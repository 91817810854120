/* You can add global styles to this file, and also import other style files */

.primeColor {
  color: green !important;
}
.primeBackground {
  background-color: green !important;
}
.primeBorder {
  border: green !important;
}
.primeButton {
  background-color: green !important;
  border: green !important;
  padding-left: 7rem !important;
  padding-right: 7rem !important;
}
.primeSmallButton {
  padding-left: 2rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 2rem !important;
  background: green !important;
  border: green !important;
  font-weight: 600 !important;
  margin-left: 15px !important;
}
.secondarySmallButton {
  padding-left: 2rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-right: 2rem !important;
  background: rgb(6, 6, 212) !important;
  border: rgb(6, 6, 212) !important;
  font-weight: 600 !important;
}
.surfaceColor {
  background-color: "var(--surface-border)" !important;
}
.title {
  font: normal normal bold 32px/39px Calibri !important;
  color: #414c5d !important;
}
.cancelButton {
  padding: 10px 50px !important;
  background-color: "#CFCDCD" !important;
  border-color: white !important;
}
.colorDanger {
  color: red !important;
}
.customIcon {
  font-size: 1.3rem;
  color: blue !important;
}
.customIconDanger {
  font-size: 1.3rem;
  color: red !important;
}
.colorSuccess {
  color: #6acf78 !important;
}
.colorWarning {
  color: orange !important;
}
